import 'swiper/swiper-bundle.min.css';
import '../css/load.scss';
import '@fancyapps/ui/dist/fancybox/fancybox.css';

import Swiper from 'swiper/swiper-bundle.min.mjs';
import List from 'list.js';
import { Fancybox } from '@fancyapps/ui';
import './jquery-ui.min.js';

(function ($) {
	const $win = $(window);
	const $doc = $(document);

	initHeaderMenuToggler();
	initScrollButton();
	initSlider('.slider-campaigns');
	initSlider('.slider-news');
	initShareButton();
	initNavScroll();
	initChipLogic();
	initClubTabs();
	initFancyBoxVideo('club');
	initFancyBoxVideo('campaign');
	initAccordion();
	initListNews('list-news');
	initListClubs('list-clubs');
	initListCampaigns('list-campaigns');
	initPasswordChecker();
	initAutoCompleteSearch();
	initInputClear();
	initRegFlow();
	handleRegFormSteps();
	scrollToSection();
	initCustomSelect();
	initTermsPopup();

	function addOverlay(ovElement) {
		const $overlay = $(`${ovElement}`);
		if (!$overlay.length) {
			return;
		}

		$('body').addClass('no-scroll');
		$overlay.addClass('active');
	}

	function removeOverlay(ovElement) {
		const $overlay = $(`${ovElement}`);
		if (!$overlay.length) {
			return;
		}

		$('body').removeClass('no-scroll');
		$overlay.removeClass('active');
	}

	function initHeaderMenuToggler() {
		if (!$('.header__menu-toggle').length) {
			return;
		}

		let $header = $('.header-side');

		$('.header .header__menu-toggle svg').on('click', function () {
			$header.toggleClass('menu-open');
			addOverlay('.app-loader-overlay');
		});

		$('.header-side .header__menu-close svg').on('click', function () {
			$header.removeClass('menu-open');
			removeOverlay('.app-loader-overlay');
		});

		$doc.mouseup(function (e) {
			if ($header.hasClass('menu-open')) {
				if (!$header.is(e.target) && $header.has(e.target).length === 0) {
					$header.removeClass('menu-open');
					removeOverlay('.app-loader-overlay');
				}
			}
		});
	}

	function initScrollButton() {
		if (!$('.btn-scroll').length) {
			return;
		}

		$('.btn-scroll').on('click', function (e) {
			e.preventDefault();

			const $parent = $(this).parents('section');
			const $nextSection = $parent.next();

			if (!$nextSection.length) {
				return;
			}

			$('html, body').animate(
				{
					scrollTop: $nextSection.offset().top - 100
				},
				1000
			);
		});
	}

	function scrollToSection() {
		$('a.btn-scroller').on('click', function (e) {
			let sectionId = $(this).attr('href');
			if (!sectionId.startsWith('#')) {
				return;
			}

			e.preventDefault();

			$('html, body').animate(
				{
					scrollTop: $(sectionId).offset().top - 40
				},
				1000
			);
		});
	}

	function initShareButton() {
		if (!$('.btn-share').length) {
			return;
		}

		$('body').on('click', '.btn-share .btn__trigger', function (e) {
			e.preventDefault();

			if ($(this).next().is(':hidden')) {
				$(this).next().fadeIn('fast');
			} else {
				$(this).next().fadeOut('fast');
			}
		});

		$('body').on('mouseleave', '.card, .card-horizontal--alt', function () {
			$(this).find('.btn__links').fadeOut('fast');
		});
	}

	function initSlider(elementSelector) {
		const swiper = new Swiper('.swiper' + elementSelector, {
			loop: true,
			slidesPerView: 3,
			spaceBetween: 16,
			autoplay: {
				delay: 2000
			},
			pagination: {
				el: '.swiper-pagination'
			},
			navigation: {
				nextEl: `${elementSelector} + .swiper-buttons .swiper-button-next`,
				prevEl: `${elementSelector} + .swiper-buttons .swiper-button-prev`
			}
		});
	}

	function initNavScroll() {
		if (!$('.acrticle__nav').length) {
			return;
		}

		$('.acrticle__nav a').on('click', function (e) {
			e.preventDefault();

			let id = $(this).attr('href');

			$('html, body').animate(
				{
					scrollTop: $(id).offset().top - 140
				},
				1000
			);
		});
	}

	function initChipLogic() {
		if (!$('.page-template-clubs').length && !$('.page-template-campaigns').length) {
			return;
		}

		// Date
		$('input[name="field-activity[]"]').on('change', function () {
			$(this).siblings('input').prop('checked', false);
		});

		// Country
		$('input[name="field-country[]"]').on('change', function () {
			if ($(this).val() !== 'All') {
				$('.input--chips input[value="All"]').prop('checked', false);
			} else {
				$('.input--chips').find('input[value!="All"]').prop('checked', false);
			}
		});
	}

	function initClubTabs() {
		if (!$('.single-app_club').length && !$('.single-app_campaign').length) {
			return;
		}

		$('.article__nav ul li a').on('click', function (e) {
			e.preventDefault();
			const tabKey = $(this).attr('href');

			$('.article__nav li a').removeClass('btn--selected');
			$(this).addClass('btn--selected');
			$('.tabs').find(`[data-tab='${tabKey}']`).show().siblings().hide();
		});
	}

	function initFancyBoxVideo(dataSelector) {
		const options = {
			Thumbs: {
				type: 'classic'
			}
		};

		Fancybox.bind(`[data-fancybox="${dataSelector}"]`, options);
	}

	function initAccordion() {
		if (!$('.accordion').length) {
			return;
		}

		$('.accordion .accordion__head').on('click', function () {
			if ($(this).next().is(':hidden')) {
				$(this).next().show('fast');
				$(this).toggleClass('accordion--close');
			} else {
				$(this).next().hide('fast');
				$(this).toggleClass('accordion--close');
			}
		});
	}

	function initListNews(elementSelector) {
		if (!$('.page-template-news').length) {
			return;
		}

		handleSortButtons('field-sort-latest', 'desc');

		let options = {
			valueNames: [{ attr: 'data-ljs-latest', name: 'ljs-latest' }],
			listClass: 'ljs-container',
			sortClass: 'ljs-sort'
		};

		let list = new List(elementSelector, options);

		$(document).ready(function () {
			list.sort('ljs-latest', { order: 'desc' });
		});
	}

	function initListClubs(elementSelector) {
		if (!$('.page-template-clubs').length) {
			return;
		}

		handleSortButtons('field-sort-title', 'asc');

		let options = {
			valueNames: [
				{ attr: 'data-ljs-title', name: 'ljs-title' },
				{ attr: 'data-ljs-country', name: 'ljs-country' }
			],
			listClass: 'ljs-container',
			sortClass: 'ljs-sort'
		};

		let list = new List(elementSelector, options);

		$('input[name="field-country[]"]').on('change', function () {
			let filterVal = $(this).val();
			let countryValsArray = $('input[name="field-country[]"]:checked')
				.map(function (_, el) {
					return $(el).val();
				})
				.get();

			if (filterVal === 'All') {
				list.filter();
			} else {
				list.filter(function (item) {
					return countryValsArray.includes(item.values()['ljs-country']);
				});
			}
		});

		$(document).ready(function () {
			list.sort('ljs-title', { order: 'asc' });
		});
	}

	function initListCampaigns(elementSelector) {
		if (!$('.page-template-campaigns').length) {
			return;
		}

		handleSortButtons('field-sort-start_date', 'asc');

		let options = {
			valueNames: [
				{ attr: 'data-ljs-activity', name: 'ljs-activity' },
				{ attr: 'data-ljs-start', name: 'ljs-start_date' },
				{ attr: 'data-ljs-end', name: 'ljs-end_date' },
				{ attr: 'data-ljs-goal', name: 'ljs-goal' },
				{ attr: 'data-ljs-progress', name: 'ljs-progress' },
				{ attr: 'data-ljs-country', name: 'ljs-country' }
			],
			listClass: 'ljs-container',
			sortClass: 'ljs-sort'
		};

		let list = new List(elementSelector, options);

		$('input[name="field-activity[]"]').on('change', function () {
			let filterVal = $(this).val();

			list.filter(function (item) {
				return item.values()['ljs-activity'] === filterVal;
			});
		});

		$(document).ready(function () {
			const filterLabels = ['ongoing', 'upcoming', 'past'];
			filterLabels.forEach((element) => {
				let elementCount = $(`[data-ljs-activity="${element}"]`).length;
				$(`[data-filter-val="${element}"]`).text(function (index, currentContent) {
					return `${currentContent} (${elementCount})`;
				});
			});

			list.filter(function (item) {
				return item.values()['ljs-activity'] === 'ongoing';
			});
			list.sort('ljs-start_date', { order: 'asc' });
		});
	}

	function handleSortButtons(initialElementId, defaultOrder) {
		let lastSortClicked = initialElementId;

		$('input[name="field-sort[]"]').on('change', function () {
			const clickedElementId = $(this).attr('id');
			const $theLabel = $(this).next('label');

			if (lastSortClicked === clickedElementId) {
				$theLabel.toggleClass('ljs-desc').toggleClass('ljs-asc');
			} else {
				$(this).siblings('label').addClass('icon-hidden');
				$theLabel.removeClass(`icon-hidden ljs-asc ljs-desc`);
				$theLabel.addClass(`ljs-${defaultOrder}`);
			}

			lastSortClicked = clickedElementId;
		});

		$('.ljs-filter').on('click', function () {
			lastSortClicked = '';
		});
	}

	// Password funcs
	function checkIfEightChar(text) {
		return text.length >= 8;
	}

	function checkIfOneLowercase(text) {
		return /[a-z]/.test(text);
	}

	function checkIfOneUppercase(text) {
		return /[A-Z]/.test(text);
	}

	function checkIfOneDigit(text) {
		return /[0-9]/.test(text);
	}

	function checkIfOneSpecialChar(text) {
		return /[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(text);
	}

	function togglePassword(inputID, buttonID) {
		let passInput = $(inputID);
		let togglePW = $(buttonID);

		passInput.get(0).type === 'password'
			? (passInput.get(0).type = 'text')
			: (passInput.get(0).type = 'password');
		togglePW.toggleClass('pass-hidden');
	}

	function initPasswordChecker() {
		if (!$('#field-pass').length) {
			return;
		}

		let $text = $('#field-pass');

		$text.on('keyup', function () {
			let passText = $(this).val();
			let $length = $('#length');
			let $lowercase = $('#lowercase');
			let $uppercase = $('#uppercase');
			let $number = $('#number');
			let $special = $('#special');

			checkIfEightChar(passText)
				? $length.addClass('form__checker-true')
				: $length.removeClass('form__checker-true');
			checkIfOneLowercase(passText)
				? $lowercase.addClass('form__checker-true')
				: $lowercase.removeClass('form__checker-true');
			checkIfOneUppercase(passText)
				? $uppercase.addClass('form__checker-true')
				: $uppercase.removeClass('form__checker-true');
			checkIfOneDigit(passText)
				? $number.addClass('form__checker-true')
				: $number.removeClass('form__checker-true');
			checkIfOneSpecialChar(passText)
				? $special.addClass('form__checker-true')
				: $special.removeClass('form__checker-true');
		});

		$('#field-pass-toggle').on('click', function () {
			togglePassword('#field-pass', '#field-pass-toggle');
		});
		$('#field-rpass-toggle').on('click', function () {
			togglePassword('#field-rpass', '#field-rpass-toggle');
		});
	}
	// Password funcs end

	function initAutoCompleteSearch() {
		if (!$('#field-search').length) {
			return;
		}

		$('#field-search').autocomplete({
			source: appData.allTeams
		});
	}

	function initInputClear() {
		if (!$('.btn-input-clear').length) {
			return;
		}

		$('.btn-input-clear').on('click', function () {
			$(this).siblings('input').val('');
		});
	}

	function initRegFlow() {
		if (!$('.btn-reg').length) {
			return;
		}

		$('.btn-reg').on('click', function (e) {
			e.preventDefault();

			let $parent = $(this).parents('section');
			let $formSection = $parent.siblings('section');
			if ($(this).hasClass('btn-individualt')) {
				$formSection.find('.form-register').addClass('form-register--user');
			} else {
				$formSection.find('.form-register').addClass('form-register--comp');
			}

			$parent.hide();
			$formSection.show();
		});
	}

	function handleRegFormSteps() {
		$('.form-register .btn-next').on('click', function () {
			let stepNumber = $(this).data('step');
			let stepCurrent = +stepNumber - 1;
			let currentHead = $('.form__head ul li').eq(stepCurrent);
			let nextHead = $('.form__head ul li').eq(stepNumber);

			if ($('.form-register').hasClass('form-register--user')) {
				var currentTab = $('.form__body-personal').find('.um-row').eq(stepCurrent);
				var nextTab = $('.form__body-personal').find('.um-row').eq(stepNumber);
			} else {
				var currentTab = $('.form__body-business').find('.um-row').eq(stepCurrent);
				var nextTab = $('.form__body-business').find('.um-row').eq(stepNumber);
			}

			$('.section-register-form').attr('data-step', +stepNumber + 1);

			currentHead.removeClass('active').addClass('done');
			nextHead.addClass('active');
			currentTab.hide();
			nextTab.show();
			$(this).data('step', ++stepNumber);

			$('html, body').animate({ scrollTop: 0 }, 'slow');
		});
	}

	function initCustomSelect() {
		$('select.lang-switcher-ppc').each(function () {
			var $this = $(this),
				numberOfOptions = $(this).children('option').length;

			$this.addClass('select-hidden');
			$this.wrap('<div class="select"></div>');
			$this.after('<div class="select-styled"></div>');

			var $styledSelect = $this.next('div.select-styled');
			$styledSelect.text($this.children('option').eq(0).text());

			var $list = $('<ul />', {
				class: 'select-options'
			}).insertAfter($styledSelect);

			for (var i = 0; i < numberOfOptions; i++) {
				$('<li />', {
					text: $this.children('option').eq(i).text(),
					rel: $this.children('option').eq(i).val()
				}).appendTo($list);
				if ($this.children('option').eq(i).is(':selected')) {
					$('li[rel="' + $this.children('option').eq(i).val() + '"]').addClass(
						'is-selected'
					);
				}
			}

			var $listItems = $list.children('li');

			$styledSelect.on('click', function (e) {
				e.stopPropagation();
				$('div.select-styled.active')
					.not(this)
					.each(function () {
						$(this).removeClass('active').next('ul.select-options').hide();
					});
				$(this).toggleClass('active').next('ul.select-options').toggle();
			});

			$listItems.on('click', function (e) {
				e.stopPropagation();
				$styledSelect.text($(this).text()).removeClass('active');
				$this.val($(this).attr('rel'));
				$list.find('li.is-selected').removeClass('is-selected');
				$list.find('li[rel="' + $(this).attr('rel') + '"]').addClass('is-selected');
				$list.hide();

				location.href = $this.val();
			});

			$(document).on('click', function () {
				$styledSelect.removeClass('active');
				$list.hide();
			});
		});
	}

	function initTermsPopup() {
		$('body').on('click', '#tou', function (e) {
			$('#section-tou').toggleClass('active');
			addOverlay('.app-loader-overlay');

			$('.section__close').on('click', function (e) {
				e.preventDefault();
				$('#section-tou').removeClass('active');
				removeOverlay('.app-loader-overlay');
			});

			$('.app-loader-overlay').on('click', function (e) {
				$('#section-tou').removeClass('active');
				removeOverlay('.app-loader-overlay');
			});
		});

		$('body').on('click', '#pp', function (e) {
			$('#section-pp').toggleClass('active');
			addOverlay('.app-loader-overlay');

			$('.section__close').on('click', function (e) {
				e.preventDefault();
				$('#section-pp').removeClass('active');
				removeOverlay('.app-loader-overlay');
			});

			$('.app-loader-overlay').on('click', function (e) {
				$('#section-pp').removeClass('active');
				removeOverlay('.app-loader-overlay');
			});
		});
	}
})(jQuery);
